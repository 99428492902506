import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Slider from "react-slick";
import Layout from "../components/layout/layout";
import SEO from "../components/seo/seo";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Main = styled.main`
  color: #232129;
  padding-top: 500px;
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    padding-top: 300px;
  }
  @media (max-width: 400px) {
    padding-top: 200px;
  }
`;

const HeroBanner = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 1440px;
}`;

const Title = styled.h1`
  font-family: Bebas Neue;
  font-size: 2rem;
  width: 100%;
  text-align: center;
  margin: 6rem 0 1rem 0;
`;

const BioText = styled.p`
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin: 1rem auto 6rem auto;
  line-height: 1.2rem;
  font-size: 1rem;
  max-width: 768px;
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  height: 500px;

  @media (max-width: 800px) {
    height: 300px;
  }
  @media (max-width: 400px) {
    height: 200px;
  }
`;

const IndexPage = ({ data }) => {
  const { title, description, mini_bio, hero_images } =
    data.prismicHomepage.data;
  var sliderSettings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
    dots: false,
    fade: true,
    infinite: true,
    lazyLoad: "ondemand",
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Layout maxWidth="100%">
      <SEO title={title} description={description}></SEO>
      <Main>
        <HeroBanner>
          <Slider {...sliderSettings}>
            {hero_images.map((photo) => (
              <StyledGatsbyImage
                key={photo.hero_photo.document.data.image.localFile.id}
                image={getImage(photo.hero_photo.document.data.image.localFile)}
                alt={photo.hero_photo.document.data.image.alt || ''}
                style={{ height: "500px" }}
                imgStyle={{ objectPosition: "top" }}
              />
            ))}
          </Slider>
        </HeroBanner>
        <Title>{title}</Title>
        <BioText>{mini_bio.text}</BioText>
      </Main>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query HomeQuery {
    prismicHomepage {
      data {
        title
        description
        mini_bio {
          text
        }
        hero_images {
          hero_photo {
            document {
              ... on PrismicPhoto {
                data {
                  image {
                    alt
                    localFile {
                      id
                      childImageSharp {
                        gatsbyImageData(
                          quality: 85
                          width: 1440
                          height: 500
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
